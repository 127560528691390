body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'HelveticaNeueLight';
  src: local('HelveticaNeueLight'),
    url('fonts/HelveticaNeueLight.ttf') format('truetype');
  font-weight: light;
}

@font-face {
  font-family: 'HelveticaNeueThin';
  src: local('HelveticaNeueThin'),
    url('fonts/HelveticaNeueThin.ttf') format('truetype');
  font-weight: thin;
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: local('HelveticaNeueMedium'),
    url('fonts/HelveticaNeueMedium.ttf') format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'HelveticaNeueBold';
  src: local('HelveticaNeueBold'),
    url('fonts/HelveticaNeueBold.ttf') format('truetype');
  font-weight: bold;
}

td{
    font-size:.8rem;
}

th{
    font-size:14px;
    font-weight:bold;
}
